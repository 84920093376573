import React from 'react';


const Navbar = () => {
    let whatButton

    const onLogout = () => {
        localStorage.removeItem('token');
    };

    if (localStorage.getItem('token')){
        whatButton = (
                <a className="btn btn-primary" href={"/"} onClick={onLogout}>Logout</a>
        )
    } else {
       whatButton = ( <a className="btn btn-primary" href="/login">Login</a>)
    }


    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid d-flex justify-content-between align-items-center">
                <a className="navbar-brand" href="https://keramik14.com/termine" style={{flex: 1}}>
                    <img src="/logok14.png" alt={"Keramik14"} width="50%" />
                </a>
                <div className="text-center" style={{flex: 1}}>
                    <span>Termine finden statt -> Verfügbare Plätze &lt;= 5</span>
                </div>
                <div style={{flex: 1}} className="text-end">
                    {whatButton}
                </div>
            </div>
        </nav>
    )
}

export default Navbar