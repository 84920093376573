import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Spinner, Alert, Button, Form } from 'react-bootstrap';

const Anmeldung = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [termin, setTermin] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [vorname, setVorname] = useState('');
    const [nachname, setNachname] = useState('');
    const [tel, setTel] = useState('');
    const [success, setSuccess] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        const fetchTermin = async () => {
            try {
                const response = await axios.get(`https://k14buchungen.ddns.net/api/termine/${id}`);
                setTermin(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTermin();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isChecked) {
            setError("Bitte bestätigen Sie, dass die Daten korrekt sind.");
            return;
        }

        try {
            const response = await axios.post(`https://k14buchungen.ddns.net/api/termine/${id}/personen`, {
                vorname,
                nachname,
                tel
            });

            if (response.status === 200) {
                setSuccess(true); // Zeigt eine Erfolgsmeldung an
                const updatedResponse = await axios.get(`https://k14buchungen.ddns.net/api/termine/${id}`);
                setTermin(updatedResponse.data);
                setVorname(''); // Formular zurücksetzen
                setNachname('');
                setTel('');
                setIsChecked(false);
            } else {
                setError("Es gab ein Problem bei der Anmeldung.");
            }
        } catch (err) {
            setError(err.message);
        }
    };


    if (loading) return <Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>;
    if (error) return <Alert variant="danger">Fehler: {error}</Alert>;

    return (
        <div className="container mt-5">
            <Button variant="secondary" onClick={() => navigate(-1)} className="mb-3">
                &larr; Zurück
            </Button>
            <h2>Anmeldung für Termin: {termin.name}</h2>
            <p><strong>Datum:</strong> {new Date(termin.datum).toLocaleDateString()}</p>
            <p><strong>Uhrzeit:</strong> {termin.uhrzeit}</p>
            <p><strong>Verfügbare Plätze:</strong> {termin.vplaetze}</p>

            {success && <Alert variant="success">Sie haben sich erfolgreich angemeldet!</Alert>}

            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formVorname">
                    <Form.Label>Vorname</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Geben Sie Ihren Vornamen ein"
                        value={vorname}
                        onChange={(e) => setVorname(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId="formNachname">
                    <Form.Label>Nachname</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Geben Sie Ihren Nachnamen ein"
                        value={nachname}
                        onChange={(e) => setNachname(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId="formTel">
                    <Form.Label>Telefonnummer</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Geben Sie Ihre Telefonnummer ein"
                        value={tel}
                        onChange={(e) => setTel(e.target.value)}
                    />
                </Form.Group>
                <br/>
                <br/>
                <Form.Check
                    type="checkbox"
                    id="data-check"
                    label="Daten korrekt?"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                />
                <br/>
                <Button variant="primary" type="submit" disabled={termin.vplaetze <= 0 || !isChecked}>
                    Anmelden
                </Button>
            </Form>
        </div>
    );
};

export default Anmeldung;
