import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Table, Spinner, Alert, Button} from 'react-bootstrap';
import Navbar from './Navbar'

const TermineList = () => {
    const [termine, setTermine] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTermine = async () => {
            try {
                const response = await axios.get('https://k14buchungen.ddns.net/api/termine'); // Passe die URL an
                console.log(response)
                const fetchedTermine = response.data;
                fetchedTermine.sort((a, b) => new Date(a.datum) - new Date(b.datum));

                setTermine(fetchedTermine);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTermine();
    }, []);

    if (loading) return <Spinner animation="border" role="status"><span
        className="visually-hidden">Loading...</span></Spinner>;
    if (error) return <Alert variant="danger">Fehler: {error}</Alert>;

    return (
        <>
            {<Navbar/>}

            <br/>
            <div className="container">
            <Table striped bordered hover>
                    <thead className="thead-dark text-center">
                    <tr>
                        <th>Name</th>
                        <th>Datum</th>
                        <th>Uhrzeit</th>
                        <th>Verfügbare Plätze</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {termine.map((termin) => (
                        <tr key={termin._id} className="text-center">
                            <td>{termin.name}</td>
                            <td>{new Date(termin.datum).toLocaleDateString()}</td>
                            <td>{termin.uhrzeit}</td>
                            <td>{termin.vplaetze}</td>
                            <td>
                                <Button
                                    href={`/anmeldung/${termin._id}`}
                                    variant="primary"
                                    disabled={termin.vplaetze <= 0}>
                                    Anmelden
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default TermineList;
