import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useParams, useNavigate} from 'react-router-dom';
import {Form, Button, Alert, Spinner} from 'react-bootstrap';

const TerminBearbeiten = () => {
    const {id} = useParams(); // Holt sich die ID des Termins aus der URL
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [datum, setDatum] = useState('');
    const [uhrzeit, setUhrzeit] = useState('');
    const [vplaetze, setVplaetze] = useState('');
    const [personen, setPersonen] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);


    useEffect(() => {
        const fetchTermin = async () => {
            try {
                const response = await axios.get(`https://k14buchungen.ddns.net/api/termine/${id}`);
                const termin = response.data;

                setName(termin.name);
                setDatum(termin.datum.split('T')[0]); // Nur das Datum-Teil
                setUhrzeit(termin.uhrzeit);
                setVplaetze(termin.vplaetze);
                setPersonen(termin.personen);
                setLoading(false);
            } catch (err) {
                setError('Fehler beim Laden des Termins.');
                setLoading(false);
            }
        };

        fetchTermin();
    }, [id]);

    useEffect(() => {
        if (success) {
            const timer = setTimeout(() => {
                setSuccess(false);
            }, 3000); // Alert nach 3 Sekunden ausblenden

            return () => clearTimeout(timer); // Timer aufräumen
        }
    }, [success]);

    const handlePersonRemove = (index) => {
        const newPersonen = personen.filter((_, i) => i !== index);
        setPersonen(newPersonen);

        // Verfügbare Plätze erhöhen
        setVplaetze(prevVplaetze => prevVplaetze + 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedTermin = {
            name,
            datum,
            uhrzeit,
            vplaetze: parseInt(vplaetze),
            personen,
        };

        try {
            await axios.patch(`https://k14buchungen.ddns.net/api/termine/${id}`, updatedTermin);
            setSuccess(true);
            setError(null);
        } catch (err) {
            setError('Fehler beim Aktualisieren des Termins.');
            setSuccess(false);
        }
    };


    const handleDelete = async () => {
        try {
            await axios.delete(`https://k14buchungen.ddns.net/api/termine/${id}`);
            setError(null);
            navigate("/admin"); // Navigiere zurück zur Admin-Seite oder einer anderen Seite nach dem Löschen
        } catch (err) {
            setError('Fehler beim Löschen des Termins.');
        }
    };

    if (loading) return <Spinner animation="border" role="status"><span
        className="visually-hidden">Loading...</span></Spinner>;
    if (error) return <Alert variant="danger">{error}</Alert>;

    return (
        <div className="container mt-5">

            <Button variant="secondary" href="/admin" className="mb-3">
                &larr; Zurück
            </Button>
            <br/>
            <h2>Termin bearbeiten</h2>

            {success && <Alert variant="success">Termin erfolgreich aktualisiert!</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}

            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formName">
                    <Form.Label>Termin Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Geben Sie den Termin-Namen ein"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formDatum">
                    <Form.Label>Datum</Form.Label>
                    <Form.Control
                        type="date"
                        value={datum}
                        onChange={(e) => setDatum(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formUhrzeit">
                    <Form.Label>Uhrzeit</Form.Label>
                    <Form.Control
                        type="time"
                        value={uhrzeit}
                        onChange={(e) => setUhrzeit(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formVplaetze">
                    <Form.Label>Verfügbare Plätze</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Anzahl der verfügbaren Plätze"
                        value={vplaetze}
                        onChange={(e) => setVplaetze(e.target.value)}
                        required
                    />
                </Form.Group>

                <br/>

                <h4>Angemeldete Personen</h4>
                {personen.length > 0 ? (
                    personen.map((person, index) => (
                        <div key={index} className="d-flex align-items-center mb-2">
                            <span>{person.vorname} {person.nachname} - Tel: {person.tel}</span>
                            <Button
                                variant="danger"
                                className="ms-3"
                                onClick={() => handlePersonRemove(index)}
                            >
                                Entfernen
                            </Button>
                        </div>
                    ))
                ) : (
                    <p>Keine Personen angemeldet.</p>
                )}

                <br/>

                <div className="d-grid gap-2">
                    <Button variant="primary" type="submit">
                        Termin aktualisieren
                    </Button>
                    <button type="button" className="btn btn-danger" onClick={handleDelete}>
                        ⚠️Termin löschen⚠️
                    </button>
                </div>
            </Form>
        </div>
    );
};

export default TerminBearbeiten;
