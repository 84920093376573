import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Alert } from 'react-bootstrap';

const TerminErstellen = () => {
  const [name, setName] = useState('');
  const [datum, setDatum] = useState('');
  const [uhrzeit, setUhrzeit] = useState('');
  const [vplaetze, setVplaetze] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Erstelle ein neues Termin-Objekt
    const newTermin = {
      name,
      datum,
      uhrzeit,
      vplaetze: parseInt(vplaetze),
      personen: []
    };

    try {
      // Sende das POST-Anfrage an den Server
      await axios.post('https://k14buchungen.ddns.net/api/termine', newTermin);
      setSuccess(true);
      setError(null);
      // Leere das Formular nach dem Senden
      setName('');
      setDatum('');
      setUhrzeit('');
      setVplaetze('');
    } catch (err) {
      setError('Fehler beim Erstellen des Termins. Bitte versuchen Sie es erneut.');
      setSuccess(false);
    }
  };

  return (
    <div className="container mt-5">

      <Button variant="secondary" href="/admin" className="mb-3">
        &larr; Zurück
      </Button>

      <h2>Neuen Termin erstellen</h2>

      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">Termin erfolgreich erstellt!</Alert>}

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Termin Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Geben Sie den Termin-Namen ein"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formDatum">
          <Form.Label>Datum</Form.Label>
          <Form.Control
            type="date"
            value={datum}
            onChange={(e) => setDatum(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formUhrzeit">
          <Form.Label>Uhrzeit</Form.Label>
          <Form.Control
            type="time"
            value={uhrzeit}
            onChange={(e) => setUhrzeit(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formVplaetze">
          <Form.Label>Verfügbare Plätze</Form.Label>
          <Form.Control
            type="number"
            placeholder="Anzahl der verfügbaren Plätze"
            value={vplaetze}
            onChange={(e) => setVplaetze(e.target.value)}
            required
          />
        </Form.Group>

        <br />
        <Button variant="primary" type="submit">
          Termin erstellen
        </Button>
      </Form>
    </div>
  );
};

export default TerminErstellen;
