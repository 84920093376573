import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import TermineList from "./components/TermineList";
import Anmeldung from "./components/Anmeldung";
import Login from "./components/Login";
import AdminDashboard from "./components/AdminDashboard";
import TerminErstellen from "./components/TerminErstellen";
import TerminBearbeiten from "./components/TerminBearbeiten";

function App() {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setLoggedIn(true);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        setLoggedIn(false);
    };

    let adminRoutes;
    if (localStorage.getItem('token')) {
        console.log(loggedIn)
        adminRoutes = (
            <>
                <Route path="/admin" element={<AdminDashboard onLogout={handleLogout} />} />
                <Route path="/admin/create" element={<TerminErstellen />} />
                <Route path="/admin/edit/:id" element={<TerminBearbeiten />} />
            </>
        );
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<TermineList />} />
                <Route path="/anmeldung/:id" element={<Anmeldung />} />
                <Route path="/login" element={<Login setLoggedIn={setLoggedIn} />} />
                {adminRoutes}
            </Routes>
        </BrowserRouter>
    );
}

export default App;
